<template>
  <LinkButtonWrapper
    :to="$props.to"
    :href="$props.href"
    :target="$props.target"
    :aria-label="$props.social">
    <div
      ref="socialButton"
      class="socialButton"
      :class="{
        'socialButton--link': $props.social === SocialItems.link,
        'socialButton--twitter': $props.social === SocialItems.twitter,
        'socialButton--facebook': $props.social === SocialItems.facebook,
        'socialButton--youtube': $props.social === SocialItems.youtube,
        'socialButton--instagram': $props.social === SocialItems.instagram,
        'socialButton--linkedin': $props.social === SocialItems.linkedin,
        'socialButton--glassdoor': $props.social === SocialItems.glassdoor,
        'socialButton--bilibili': $props.social === SocialItems.bilibili,
        'socialButton--tiktok': $props.social === SocialItems.tiktok,
      }">
      <div class="socialButton__icon" />
    </div>
  </LinkButtonWrapper>
</template>

<script>
import LinkButtonWrapper from '../LinkButtonWrapper.vue'

export const SocialItems = {
  link: 'link',
  facebook: 'facebook',
  twitter: 'twitter',
  youtube: 'youtube',
  instagram: 'instagram',
  linkedin: 'linkedin',
  glassdoor: 'glassdoor',
  bilibili: 'bilibili',
  tiktok: 'tiktok',
}

export default {
  name: 'SocialButton',
  components: {
    LinkButtonWrapper,
  },
  props: {
    href: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '_blank',
    },
    to: {
      type: String,
      default: '',
    },
    social: {
      type: String,
      validator(value) {
        return Object.values(SocialItems).includes(value)
      },
      default: 'link',
    },
  },
  data() {
    return {
      SocialItems,
    }
  },
}
</script>

<style lang="scss" scoped>
// TODO - Add hover state & cleanup CSS
.socialButton {
  $block: &;

  display: inline-block;

  &__icon {
    @include centered-background($bg-size: 100% auto);

    width: size(mobile, default-icon);
    height: size(mobile, default-icon);

    #{$block}--link & {
      background-image: url('../../assets/icons/icon-social-link-white.svg');
    }

    #{$block}--twitter & {
      background-image: url('../../assets/icons/icon-social-twitter-white.svg');
    }

    #{$block}--facebook & {
      background-image: url('../../assets/icons/icon-social-facebook-white.svg');
    }

    #{$block}--youtube & {
      width: layout-grid(4);
      background-image: url('../../assets/icons/icon-social-youtube-white.svg');
    }

    #{$block}--instagram & {
      background-image: url('../../assets/icons/icon-social-instagram-white.svg');
    }

    #{$block}--linkedin & {
      background-image: url('../../assets/icons/icon-social-linkedin-white.svg');
    }

    #{$block}--glassdoor & {
      background-image: url('../../assets/icons/icon-social-glassdoor-white.svg');
    }

    #{$block}--bilibili & {
      background-image: url('../../assets/icons/icon-social-bilibili.svg');
    }

    #{$block}--tiktok & {
      height: 26px;
      background-image: url('../../assets/icons/icon-social-tiktok.svg');
    }
  }
}
</style>

import { RouteConfig } from 'vue-router'
import routeNames from './routeNames'
import { SUPPORTED_LANGS_EXP } from '@/constants'
import { i18n } from '@/config/i18n'

export const routes: RouteConfig[] = [
  {
    path: SUPPORTED_LANGS_EXP,
    name: routeNames.home,
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
  },
  {
    // Won't localize auth in url - but content will be translatable
    path: '/auth',
    name: routeNames.auth,
    component: () => import(/* webpackChunkName: "auth" */ '@/views/Auth.vue'),
  },
  {
    path: '*',
    redirect: { path: i18n.locale },
  },
]

<template>
  <div
    @click="$props.func"
    class="RectangleButton"
    :class="{
      'RectangleButton--slanted': $props.slanted,
      'RectangleButton--inactive': $props.inactive,
      'RectangleButton--disabled': $props.disabled,
      'RectangleButton--withNoPadding': $props.withNoPadding,
      'RectangleButton--withSmallPadding': $props.withSmallPadding,
      'RectangleButton--imageButton': $props.isImageButton,
      'RectangleButton--dark': $props.color == ButtonColors.dark,
      'RectangleButton--blue': $props.color == ButtonColors.blue,
      'RectangleButton--hasIcon': $props.hasIcon,
    }">
    <div
      class="RectangleButton__background"
      :class="{
        'RectangleButton__background--inactive': !$props.correct && $props.inactive,
      }">
      <div
        v-if="$props.selected"
        :class="{
          'RectangleButton--pickedBorder': $props.selected,
        }" />

      <div
        v-if="!$props.selected && $props.correct"
        :class="{
          'RectangleButton--correctBorder': $props.correct && $props.inactive,
        }" />
      <div
        v-if="$props.selected && $props.correct && $props.inactive"
        class="RectangleButton--correctIcon" />

      <PickedLabel :disabled="$props.disabled">
        {{ $props.label }}
      </PickedLabel>

      <div class="RectangleButton__icon" v-if="$props.hasIcon">
        <!-- Spacing for icon -->
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import PickedLabel from '../PickedLabel.vue'

export const ButtonColors = {
  yellow: 'yellow',
  purple: 'purple',
  dark: 'dark',
  blue: 'blue',
  red: 'red',
}
export default Vue.extend({
  name: 'RectangleButton',
  components: {
    PickedLabel,
  },
  props: {
    func: {
      type: Function,
      default: () => {},
    },
    selector: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Label',
    },
    href: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '_self',
    },
    slanted: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      validator(value) {
        return Object.values(ButtonColors).includes(value)
      },
      default: ButtonColors.yellow,
    },
    selected: {
      // Users selected option
      type: Boolean,
      default: false,
    },
    stretch: {
      type: Boolean,
      default: false,
    },
    inactive: {
      type: Boolean,
      default: false,
    },
    withNoPadding: {
      type: Boolean,
      default: false,
    },
    withSmallPadding: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasIcon: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    ButtonColors,
    showGlow: false,
    startTouchTarget: null,
  }),
})
</script>

<style lang="scss" scoped>
.RectangleButton {
  $block: &;
  height: 100%;
  width: 100%;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  line-height: 28px;
  min-height: 20px;
  max-height: 51px;
  margin-bottom: 2px;
  pointer-events: all;
  position: relative;
  background-color: transparent;
  @include theme-border(true);

  &__icon {
    position: relative;
    display: inline;
    width: 32px;
    height: 32px;
    margin: 0 10px;
  }

  &::after {
    content: '';
    position: absolute;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    bottom: -7px;
    left: -2px;
    background: black;
    border-radius: 5px;
    opacity: 0.25;
    z-index: -1;
  }

  &--inactive::after {
    display: none;
  }

  &__background {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    @include theme-color(purple, true);
    border-radius: 2px;
    padding: 0 20px 0 20px;
    box-shadow: 1px 1px black, -1px -1px black; //Chrome rendering fix
    #{$block}--withNoPadding & {
      min-width: 0;
      padding: 0;
    }
    #{$block}--withSmallPadding & {
      min-width: 0;
    }

    #{$block}--hasIcon & {
      padding-left: 60px;
    }
  }
  &__background::after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 8px;
    width: 8px;
    background-color: color('#32A3FE');
    clip-path: polygon(0 0, 100% 100%, 100% 0);
    border-radius: 0 3px 0 0;
  }
  &__label {
    @include white-header-font-uppercase('button');
    @include flex-center();
    font-size: 18px;
    position: relative;
    padding: 6px 5px;
    &--incorrect {
      text-shadow: unset;
    }

    #{$block}--withNoPadding & {
      text-transform: none;
      font-size: 16px;
      @include mq($until: mobile-regular) {
        font-size: 14px;
        line-height: 20px;
      }
      @media only screen and (max-device-width: 375px) and (orientation: landscape) {
        font-size: 14px;
        line-height: 18px;
      }
    }
    #{$block}--disabled & {
      opacity: 0.5;
    }
  }

  &--selector {
    flex: 1;
  }
  &--slanted {
    @include skew();
    #{$block}_label_unskew {
      @include unskew();
    }
  }
  &--stretch {
    width: 100%;
  }

  &--dark {
    #{$block}__background {
      @include theme-color(dark, true);
    }
    #{$block}__background::after {
      background-color: color('#32A3FE');
    }
  }

  &--dark:active,
  &--dark#{$block}--progress {
    #{$block}__background {
      background-color: color(dark-minus1);
      border-color: color(dark-minus1);
    }
    #{$block}__background::after {
      background-color: transparent;
    }
  }
  &--dark:active {
    #{$block}__label {
      color: color(dark-plus2);
    }
  }
  &--dark#{$block}--inactive {
    #{$block}__label {
      color: color(dark-plus1);
    }
  }

  &--blue {
    #{$block}__background {
      @include theme-color(blue, true);
    }

    #{$block}__background::after {
      background-color: #32a3fe;
    }
  }
  &--blue:active,
  &--blue#{$block}--progress {
    #{$block}__background {
      background-color: color(blue-minus2);
      border-color: color(blue-minus2);
    }
    #{$block}__background::after {
      background-color: transparent;
    }
  }
  &--blue:active {
    #{$block}__label {
      color: color(blue-plus2);
    }
  }

  &--blue:hover {
    #{$block}__background {
      @include theme-color(blue, true);
    }
    #{$block}__background::after {
      background-color: #32a3fe;
    }
  }

  &--imageButton {
    width: 100%;
    height: 100%;
    #{$block}__background {
      width: 100%;
      height: 100%;
    }
    #{$block}__label {
      width: 100%;
      height: 100%;
      &__unskew {
        width: 100%;
        height: 100%;
      }
    }
  }

  &--inactive {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;

    #{$block}__background {
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
    }
    #{$block}__background::after {
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
    }
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  // Hover state
  &:hover {
    &--blue {
      #{$block}__background {
        @include theme-color(blue, true);
      }
    }

    #{$block}__background {
      &::before {
        @include absolute-fill();
        border-radius: 3px;
        content: ' ';
        z-index: 999;
        background-color: rgba(0, 0, 0, 0.15);
      }
    }
  }
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import { useAccessor, getterTree, mutationTree, actionTree, createMapper } from 'typed-vuex'
import auth from '@/stores/auth'
import user from '@/stores/user'
import pinStore from '@/stores/pin-store'
import { Categories, COLORING_FEATURES } from '@/constants/elements'
import randomEnumItem from '@/helpers/random-enum-item'
import { i18n } from '@/config/i18n'
import router from '@/router'

Vue.use(Vuex)

const APP_RECOVER_STORAGE_ID = 'app.recover.state'

enum EditMode {
  ACTIONS = 'actions',
  MOVE = 'move',
  ROTATE = 'rotate',
  SCALE = 'scale',
  PICKING_COLOR = 'picking-color',
  DOWNLOAD = 'download',
  UPLOAD = 'upload'
}

interface State {
  footerVisible: boolean
  editMode: EditMode
  categoryMenuOpen: boolean
  activeCategory: Categories
  isLandscape: boolean
  isTablet: boolean
  isDesktop: boolean
  controlsScale: number // Quick little hack for desktop layout. Scale all controls based on screen width.
  previewBox: boolean,
  uploadTooltip: boolean
}

const state = (): State => ({
  footerVisible: false,
  editMode: EditMode.ACTIONS,
  categoryMenuOpen: false,
  activeCategory: randomEnumItem(Categories),
  isLandscape: false,
  isTablet: false,
  isDesktop: false,
  controlsScale: 1,
  previewBox: false,
  uploadTooltip: window.localStorage.getItem('uploadTooltip') !== '0',
})

const getters = getterTree(state, {
  editing: state => state.editMode !== EditMode.DOWNLOAD && state.editMode !== EditMode.UPLOAD,
  moving: state => state.editMode === EditMode.MOVE,
  rotating: state => state.editMode === EditMode.ROTATE,
  scaling: state => state.editMode === EditMode.SCALE,
  pickingColor: state => state.editMode === EditMode.PICKING_COLOR,
  interacting: state =>
    state.editMode === EditMode.MOVE ||
    state.editMode === EditMode.ROTATE ||
    state.editMode === EditMode.SCALE ||
    state.editMode === EditMode.PICKING_COLOR,
  downloading: state => state.editMode === EditMode.DOWNLOAD,
  uploading: state => state.editMode === EditMode.UPLOAD,
  colorPickerSupported(state): boolean {
    return COLORING_FEATURES.includes(state.activeCategory)
  },
  previewBox: (state, getters) => { return state.previewBox },
  uploadTooltip: state => state.uploadTooltip
})

const mutations = mutationTree(state, {
  setControlsScale(state, scale: number) {
    state.controlsScale = scale
  },
  setCategoryMenuOpen(state, open: boolean) {
    state.categoryMenuOpen = open
  },
  setActiveCategory(state, category: Categories) {
    state.activeCategory = category
  },
  showFooter(state, open: boolean) {
    state.footerVisible = open
  },
  setEditMode(state, mode: EditMode) {
    state.editMode = mode
  },
  setIsLandscape(state, isLandscape: boolean) {
    state.isLandscape = isLandscape
  },
  setIsTablet(state, isTablet: boolean) {
    state.isTablet = isTablet
  },
  setIsDesktop(state, isDesktop: boolean) {
    state.isDesktop = isDesktop
  },
  setPreviewBox(state, show: boolean) {
    state.previewBox = show
  },
  setUploadTooltip(state, show: boolean) {
    state.uploadTooltip = show

    if (!show) {
      window.localStorage.setItem('uploadTooltip', '0')
    } else {
      window.localStorage.removeItem('uploadTooltip')
    }
  }
})

const actions = actionTree(
  { state, getters, mutations },
  {
    saveAppStateToLocalStorage({ state, getters, dispatch }) {
      // App
      const appState = {
        locale: i18n.locale,
        editMode: state.editMode
      }
      window.localStorage.setItem(APP_RECOVER_STORAGE_ID, JSON.stringify(appState))

      // Pin
      dispatch('pin/savePinToLocalStorage', null, { root: true })
    },
    loadAppStateFromLocalStorage({ dispatch }): Promise<void> {
      return new Promise((resolve, reject) => {
        // Load pin
        dispatch('pin/recoverPinFromLocalStorage', null, { root: true })
          .then(() => {
            // Load app state
            const recoverStateStorage = localStorage.getItem(APP_RECOVER_STORAGE_ID)

            if (!recoverStateStorage) reject(new Error('No saved app state found'))
            // Saved app state found - Move on with parsing
            else {
              const recoverState = JSON.parse(recoverStateStorage)

              // Set locale
              router.push({ path: recoverState.locale }).catch(() => {})

              // Set edit mode
              if (recoverState.editMode) dispatch('setEditMode', recoverState.editMode)
            }

            window.localStorage.removeItem(APP_RECOVER_STORAGE_ID)

            resolve()
          })
          .catch(() => {
            reject(new Error('Failed to load app state'))
          })
      })
    },
    setActiveCategory({ commit }, category: Categories) {
      commit('setActiveCategory', category)
      commit('setCategoryMenuOpen', false)
    },
    showFooter({ commit }, show: boolean) {
      commit('showFooter', show)
    },
    setEditMode({ commit }, editMode: EditMode) {
      commit('setEditMode', editMode)
    },
    setMoving({ commit }, moving: boolean) {
      commit('setEditMode', moving ? EditMode.MOVE : EditMode.ACTIONS)
    },
    setRotating({ commit }, rotating: boolean) {
      commit('setEditMode', rotating ? EditMode.ROTATE : EditMode.ACTIONS)
    },
    setScaling({ commit }, scaling: boolean) {
      commit('setEditMode', scaling ? EditMode.SCALE : EditMode.ACTIONS)
    },
    setPickingColor({ commit }, pickingColor: boolean) {
      commit('setEditMode', pickingColor ? EditMode.PICKING_COLOR : EditMode.ACTIONS)
    },
    finishEditingDownload({ commit }, finished: boolean) {
      commit('setEditMode', finished ? EditMode.DOWNLOAD : EditMode.ACTIONS)
    },
    finishEditingUpload({ commit }, finished: boolean) {
      commit('setEditMode', finished ? EditMode.UPLOAD : EditMode.ACTIONS)
    },
    showPreviewBox({ commit }, show: boolean) {
      commit('setPreviewBox', show)
    },
    showUploadTooltip({ commit }, show: boolean ) {
      commit('setUploadTooltip', show)
    }
  }
)

const storePattern = {
  state,
  getters,
  mutations,
  actions,
  modules: { pin: pinStore, auth, user },
}

// Exports
const store = new Vuex.Store(storePattern)
export const typedStore = useAccessor(store, storePattern)
export const mapper = createMapper(typedStore)
Vue.prototype.$typedStore = typedStore
export default store

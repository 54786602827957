import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import App from '@/App.vue'
import { SENTRY_DSN, APP_VERSION, ENVIRONMENT, IS_DEV } from '@/constants'
import store from '@/stores/store'
import router from '@/router'
import { i18n } from './config/i18n'

// Configs
import '@/config/axios'

// Directives
import './directives/v-visible'

// Vue setup
Vue.config.productionTip = false

// Sentry setup
Sentry.init({
  Vue,
  dsn: SENTRY_DSN,

  debug: IS_DEV,
  release: `pinmaker@${APP_VERSION}`,
  environment: ENVIRONMENT,

  logErrors: IS_DEV,
  attachProps: true,
  attachStacktrace: true,
})

// Render app
new Vue({
  render: h => h(App),
  store,
  router,
  i18n
}).$mount('#app')

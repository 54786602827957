var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", { staticClass: "footer" }, [
    _c("div", { staticClass: "navBar" }, [
      _c(
        "div",
        { staticClass: "footer__navCloseButton" },
        [
          _c("RectangleButton", {
            attrs: {
              slanted: "",
              color: "dark",
              label: "x",
              func: () => _vm.$emit("close"),
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "inner" }, [
      _c("div", { staticClass: "langSelect__wrapper" }, [
        _c(
          "div",
          { staticClass: "langSelect" },
          [
            _c("LocaleSelect", {
              attrs: {
                options: _vm.LOCALE_OPTIONS,
                value: _vm.$i18n.locale,
                color: "white",
                textColor: "white",
              },
              on: { change: _vm.onLocaleChange },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "gameInfoContainer" }, [
        _c("div", { staticClass: "header" }),
        _c("div", { staticClass: "linksContainer" }, [
          _c("div", { staticClass: "socials" }, [
            _c("p", { domProps: { textContent: _vm._s(_vm.$t("gen_fol")) } }),
            _c(
              "div",
              { staticClass: "socials__buttons" },
              [
                _c("SocialButton", {
                  attrs: {
                    href: _vm.$t("footer_li_youtube"),
                    social: "youtube",
                  },
                }),
                _c("SocialButton", {
                  attrs: {
                    href: _vm.$t("footer_li_facebook"),
                    social: "facebook",
                  },
                }),
                _c("SocialButton", {
                  attrs: {
                    href: _vm.$t("footer_li_instagram"),
                    social: "instagram",
                  },
                }),
                _c("SocialButton", {
                  attrs: {
                    href: _vm.$t("footer_li_twitter"),
                    social: "twitter",
                  },
                }),
                _c("SocialButton", {
                  attrs: { href: _vm.$t("footer_li_tiktok"), social: "tiktok" },
                }),
                _c("SocialButton", {
                  attrs: {
                    href: _vm.$t("footer_li_linkedin"),
                    social: "linkedin",
                  },
                }),
                _c("SocialButton", {
                  attrs: {
                    href: _vm.$t("footer_li_glassdoor"),
                    social: "glassdoor",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "downloadButtonsContainer" }, [
            _c("p", {
              domProps: { textContent: _vm._s(_vm.$t("footer_gen_dl_gam")) },
            }),
            _c(
              "a",
              { attrs: { href: _vm.$t("link_BS_apap"), target: "_blank" } },
              [
                _c("picture", [
                  _c("img", {
                    staticClass: "apple",
                    attrs: {
                      src: `/Download_on_the_App_Store_Badge_${this.$i18n.locale}.svg`,
                      alt: "App Store",
                    },
                    on: { error: _vm.updateAppStoreSrc },
                  }),
                  _c("source", { attrs: { srcset: "/app-store.png" } }),
                ]),
              ]
            ),
            _c(
              "a",
              { attrs: { href: _vm.$t("link_BS_apgl"), target: "_blank" } },
              [
                _c("picture", [
                  _c("img", {
                    staticClass: "img-gplay",
                    attrs: {
                      src: `/google-play-badge__${this.$i18n.locale}.png`,
                      alt: "Google Play",
                    },
                    on: { error: _vm.updateGPlaySrc },
                  }),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "externalLinksContainer" }, [
        _c("a", {
          attrs: { href: _vm.$t("footer_li_tos_url"), target: "_blank" },
          domProps: { textContent: _vm._s(_vm.$t("footer_li_tos_label")) },
        }),
        _c("a", {
          attrs: { href: _vm.$t("footer_li_pp_url"), target: "_blank" },
          domProps: { textContent: _vm._s(_vm.$t("footer_li_pp_label")) },
        }),
        _c("a", {
          attrs: { href: _vm.$t("footer_li_pg_url"), target: "_blank" },
          domProps: { textContent: _vm._s(_vm.$t("footer_li_pg_label")) },
        }),
        _c("a", {
          attrs: { href: _vm.$t("footer_li_safp_url"), target: "_blank" },
          domProps: { textContent: _vm._s(_vm.$t("footer_li_safp_label")) },
        }),
      ]),
      _c("div", { staticClass: "supercellInfoContainer" }, [
        _c("div", { staticClass: "addressContainer" }, [
          _c("p", { domProps: { textContent: _vm._s(_vm.$t("gen_add1")) } }),
          _c("p", { domProps: { textContent: _vm._s(_vm.$t("gen_add2")) } }),
          _c("p", { domProps: { textContent: _vm._s(_vm.$t("gen_add3")) } }),
          _c("p", { domProps: { textContent: _vm._s(_vm.$t("gen_add4")) } }),
        ]),
        _c("div", { staticClass: "logoContainer" }, [
          _c("a", { attrs: { href: _vm.$t("footer_li_sc_url") } }, [
            _c("img", {
              attrs: {
                src: "/Supercell.svg",
                alt: _vm.$t("footer_li_sc_label"),
              },
            }),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
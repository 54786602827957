import type { TrackPoint } from '@/types/window'

function track(data: TrackPoint) {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(data)
  }
}

export function trackMountedEvent() {
  track({
    event: 'Landing',
    pageTitle: 'Brawl Stars Pin Maker',
    pageUrl: '/',
  })
}

export function trackDownloadEvent() {
  track({
    event: 'GAEvent',
    eventCategory: 'Button Click',
    eventAction: 'Download PNG',
    eventLabel: '/',
  })
}

<template>
  <footer class="footer">
    <div class="navBar">
      <div class="footer__navCloseButton">
        <RectangleButton slanted color="dark" label="x" :func="() => $emit('close')" />
      </div>
    </div>

    <div class="inner">
      <div class="langSelect__wrapper">
        <div class="langSelect">
          <LocaleSelect
            :options="LOCALE_OPTIONS"
            :value="$i18n.locale"
            :color="'white'"
            :textColor="'white'"
            @change="onLocaleChange" />
        </div>
      </div>
      <div class="gameInfoContainer">
        <div class="header">
          <!-- <div class="downloadButtonsHeader"> -->
          <!-- <p v-text="$t('footer_gen_dl_gam')"></p> -->
          <!-- </div> -->
        </div>
        <div class="linksContainer">
          <div class="socials">
            <p v-text="$t('gen_fol')" />
            <div class="socials__buttons">
              <SocialButton :href="$t('footer_li_youtube')" social="youtube" />

              <SocialButton :href="$t('footer_li_facebook')" social="facebook" />

              <SocialButton :href="$t('footer_li_instagram')" social="instagram" />

              <SocialButton :href="$t('footer_li_twitter')" social="twitter" />

              <SocialButton :href="$t('footer_li_tiktok')" social="tiktok" />

              <SocialButton :href="$t('footer_li_linkedin')" social="linkedin" />

              <SocialButton :href="$t('footer_li_glassdoor')" social="glassdoor" />
            </div>
          </div>
          <div class="downloadButtonsContainer">
            <p v-text="$t('footer_gen_dl_gam')"></p>
            <a :href="$t('link_BS_apap')" target="_blank">
              <picture>
                <img
                  class="apple"
                  :src="`/Download_on_the_App_Store_Badge_${this.$i18n.locale}.svg`"
                  alt="App Store"
                  @error="updateAppStoreSrc" />
                <source srcset="/app-store.png" />
              </picture>
            </a>
            <a :href="$t('link_BS_apgl')" target="_blank">
              <picture>
                <img
                  class="img-gplay"
                  :src="`/google-play-badge__${this.$i18n.locale}.png`"
                  @error="updateGPlaySrc"
                  alt="Google Play" />
              </picture>
            </a>
          </div>
        </div>
      </div>

      <div class="externalLinksContainer">
        <a :href="$t('footer_li_tos_url')" target="_blank" v-text="$t('footer_li_tos_label')" />
        <a :href="$t('footer_li_pp_url')" target="_blank" v-text="$t('footer_li_pp_label')" />
        <a :href="$t('footer_li_pg_url')" target="_blank" v-text="$t('footer_li_pg_label')" />
        <a :href="$t('footer_li_safp_url')" target="_blank" v-text="$t('footer_li_safp_label')" />
        <!-- Hiding this while awaiting copy for all locales, english should be "Manage Cookies" -->
        <!-- <span @click="manageCookies" v-text="$t('footer_li_mc_label')" /> -->
      </div>

      <div class="supercellInfoContainer">
        <div class="addressContainer">
          <p v-text="$t('gen_add1')" />
          <p v-text="$t('gen_add2')" />
          <p v-text="$t('gen_add3')" />
          <p v-text="$t('gen_add4')" />
        </div>
        <div class="logoContainer">
          <a :href="$t('footer_li_sc_url')">
            <img src="/Supercell.svg" :alt="$t('footer_li_sc_label')" />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import SocialButton from './Buttons/SocialButton.vue'
import RectangleButton from './Buttons/RectangleButton.vue'
import LocaleSelect from './LocaleSelect.vue'
import { LOCALE_OPTIONS } from '@/constants'
import { i18n } from '@/config/i18n'

export default {
  name: 'Footer',
  props: {
    msg: String,
  },
  components: {
    SocialButton,
    RectangleButton,
    LocaleSelect,
  },
  data() {
    return {
      LOCALE_OPTIONS,
    }
  },

  methods: {
    onLocaleChange(newLocale) {
      this.$router.push({ params: { lang: newLocale.value } })
      i18n.locale = newLocale.value

      // Clear old attr and add new for locale specific styling
      document.documentElement.lang = ''
      document.documentElement.lang = newLocale.value
    },
    updateAppStoreSrc(e) {
      e.target.src = '/app-store.png'
    },
    updateGPlaySrc(e) {
      e.target.src = '/play-store.png'
    },
    manageCookies() {
      window.OneTrust?.ToggleInfoDisplay()
    },
  },
}
</script>

<style scoped lang="scss">
.footer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 500;
  height: 100%;
  font-size: 12px;
  font-family: 'SupercellText', sans-serif;
  background: #000;
  color: white;
  width: 100%;
  overflow: scroll;

  @media screen and (min-width: 1280px) {
    position: relative;
    height: auto;
    overflow: visible;
  }

  .navBar {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;

    @media screen and (min-width: 1280px) {
      display: none;
    }
  }

  &__navCloseButton {
    width: 62px;
    height: 40px;
    margin-right: -4px;
  }

  .inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 100%;
    width: 90%;
    margin: 0 5%;

    .gameInfoContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-bottom: 16px;

      @media screen and (min-width: 1280px) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        padding: 32px 0;
      }
      .header {
        display: flex;
        justify-content: space-between;
        p {
          line-height: 160%;
          color: white;
          font-weight: 700;

          @include mq($from: laptop) {
            font-size: 14px;
          }
        }
      }
      .linksContainer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-direction: column;

        @media screen and (min-width: 1280px) {
          flex-direction: row;
        }

        .socials {
          display: flex;
          flex-direction: column;

          padding-bottom: 16px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.15);

          p {
            margin-bottom: layout-grid(3);
            font-weight: 700;
            font-size: 14px;

            @include mq($until: laptop) {
              font-size: 12px;
              margin-bottom: layout-grid(2);
            }
          }

          @media screen and (min-width: 1280px) {
            border-bottom: none;
            padding-bottom: 0;
          }

          a {
            padding-right: 16px;
          }
        }

        .downloadButtonsContainer {
          border-bottom: 1px solid rgba(255, 255, 255, 0.15);

          @media screen and (min-width: 1280px) {
            border-bottom: none;
          }

          p {
            font-weight: 700;
            line-height: 100%;
            color: white;
            font-size: 14px;

            margin-bottom: layout-grid(3);
            padding-left: 10px;

            @include mq($until: laptop) {
              font-size: 12px;

              padding-left: 0;
              margin-bottom: layout-grid(1);
            }
          }

          img {
            padding: 16px 0;
            padding-right: 10px;
            height: 70px;

            @media screen and (min-width: 1280px) {
              padding: 0 10px;
              height: 50px;
            }
          }
        }
      }
    }

    .externalLinksContainer {
      margin: 16px 0;
      display: flex;
      flex-direction: column;
      font-size: 14px;

      @media screen and (min-width: 1280px) {
        margin: 32px 0;
        flex-direction: row;
        align-items: center;
      }

      a,
      span {
        color: #f2f2f2;
        text-decoration: none;
        padding-right: 32px;
        cursor: pointer;
      }
    }

    .supercellInfoContainer {
      margin-bottom: 20px;

      @media screen and (min-width: 1280px) {
        margin-bottom: 0;
      }

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 32px;

      .addressContainer {
        p {
          line-height: 100%;
          color: #808080;
        }
      }
    }
  }

  .langSelect__wrapper {
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    padding: layout-grid(2) 0;
    margin-top: layout-grid(2);

    @include mq($from: laptop) {
      padding: layout-grid(3) 0;
    }
  }
  .langSelect {
    width: fit-content;
    margin-right: layout-grid(2);

    &--footer {
      & ::v-deep select {
        font-weight: 500;
        font-size: 13px;
      }
    }

    & ::v-deep select {
      border-radius: 50px;
      border: none;
      box-shadow: shadow(module);
    }
  }
}
</style>

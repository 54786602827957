var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "icon",
      style: { height: `${_vm.size.height}px`, width: `${_vm.size.width}px` },
    },
    [
      _c(
        "svg",
        {
          staticClass: "icon__svg",
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 24 24",
            fill: "none",
            stroke: "currentColor",
            "stroke-width": "2",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
          },
        },
        [
          _c("circle", {
            attrs: { stroke: _vm.color, cx: "12", cy: "12", r: "10" },
          }),
          _c("line", {
            attrs: { stroke: _vm.color, x1: "2", y1: "12", x2: "22", y2: "12" },
          }),
          _c("path", {
            attrs: {
              stroke: _vm.color,
              d: "M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
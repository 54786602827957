import router from '@/router'
import { getterTree, mutationTree, actionTree } from 'typed-vuex'
import axios from 'axios'
import { API_STATUS_PENDING, API_STATUS_SUCCESS, API_STATUS_FAILURE } from '@/constants'

/*
interface ProfileImage {
  url: string | null,
  changeCooldownUntil: number | null
}
*/

interface State {
  getStatus: string | null

  userId: string,
  username: string,
  profileImage: string,
  profileImageUploadCooldown: number,
  updatedProfileImage: string
}

const state = (): State => ({
  getStatus: null,

  userId: window.localStorage.getItem('userId') || '',
  username: '',
  profileImage: '',
  profileImageUploadCooldown: 0,
  updatedProfileImage: ''
})

const getters = getterTree(state, {
  getStatus: state => state.getStatus,

  userId: state => state.userId,
  isLoggedIn: state => state.userId !== '',
  isUploadCooldown: state => state.profileImageUploadCooldown > 0
})

const mutations = mutationTree(state, {
  setGetStatus: (state, status: string) => {
    state.getStatus = status
  },
  setUserId: (state, userId: string) => {
    state.userId = userId

    if (userId !== '') {
      window.localStorage.setItem('userId', userId)
    } else {
      window.localStorage.removeItem('userId')
    }
  },
  setUsername: (state, username: string) => {
    state.username = username
  },
  setProfileImage: (state, profileImage: string) => {
    state.profileImage = profileImage
  },
  setUpdatedProfileImage: (state, profileImage: string) => {
    state.updatedProfileImage = profileImage
  },
  setProfileImageUploadCooldown: (state, cooldown: number) => {
    state.profileImageUploadCooldown = cooldown
  }
})

const actions = actionTree(
  { state, getters, mutations },
  {
    getUser({ commit, rootGetters }): Promise<object> {
      commit('setGetStatus', API_STATUS_PENDING)

      return axios.get('/users/me')
        .then(response => {
          commit('setGetStatus', API_STATUS_SUCCESS)

          commit('setUserId', response.data.userId)
          commit('setUsername', response.data.username)
          commit('setProfileImage', response.data.profileImage)
          commit('setProfileImageUploadCooldown', response.data.profileImageUploadCooldown)

          return Promise.resolve(response)
        })
        .catch(error => {
          commit('setGetStatus', API_STATUS_FAILURE)
          return Promise.reject(error)
        })
    },
    setUserId({ commit }, userId: string) {
      commit('setUserId', userId)
    },
    setUsername({ commit }, username: string) {
      commit('setUsername', username)
    },
    setProfileImage({ commit }, profileImage: string) {
      commit('setProfileImage', profileImage)
    },
    setUpdatedProfileImage({ commit }, profileImage: string) {
      commit('setUpdatedProfileImage', profileImage)
    },
    setProfileImageUploadCooldown({ commit }, cooldown: number) {
      commit('setProfileImageUploadCooldown', cooldown)
    },
    clear: ({ commit }) => {
      commit('setUserId', '')
      commit('setUsername', '')
      commit('setProfileImage', '')
      commit('setProfileImageUploadCooldown', 0)
    }
  }
)

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.href || _vm.to
    ? _c(
        "BaseLink",
        { attrs: { href: _vm.href, to: _vm.to, target: _vm.target } },
        [_vm._t("default")],
        2
      )
    : _c(
        "button",
        {
          staticClass: "BaseButton",
          class: {
            "BaseButton--selector": _vm.$props.selector,
            "BaseButton--adaptive-width": _vm.$props.adaptiveWidth,
          },
          attrs: { disabled: _vm.disabled },
        },
        [_vm._t("default")],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { loadLocaleFiles } from '@/helpers/loadLocaleFiles'
import { DEFAULT_LANGUAGE, Language, LANGUAGES } from '@/constants'

Vue.use(VueI18n)

export const useBrowserLanguage = () => {
  if (typeof navigator === 'undefined') return DEFAULT_LANGUAGE

  // Find preferred lang in browser
  const browserLanguage = navigator.languages
    .map(lang => lang.split('-')[0] as Language)
    .find((lang: Language) => LANGUAGES.includes(lang))

  // If no browser lang, use default
  return browserLanguage || DEFAULT_LANGUAGE
}

const browserLanguage = useBrowserLanguage()

const messages = loadLocaleFiles()
export const i18n = new VueI18n({
  locale: browserLanguage, // default locale
  messages,
})

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "RectangleButton",
      class: {
        "RectangleButton--slanted": _vm.$props.slanted,
        "RectangleButton--inactive": _vm.$props.inactive,
        "RectangleButton--disabled": _vm.$props.disabled,
        "RectangleButton--withNoPadding": _vm.$props.withNoPadding,
        "RectangleButton--withSmallPadding": _vm.$props.withSmallPadding,
        "RectangleButton--imageButton": _vm.$props.isImageButton,
        "RectangleButton--dark": _vm.$props.color == _vm.ButtonColors.dark,
        "RectangleButton--blue": _vm.$props.color == _vm.ButtonColors.blue,
        "RectangleButton--hasIcon": _vm.$props.hasIcon,
      },
      on: { click: _vm.$props.func },
    },
    [
      _c(
        "div",
        {
          staticClass: "RectangleButton__background",
          class: {
            "RectangleButton__background--inactive":
              !_vm.$props.correct && _vm.$props.inactive,
          },
        },
        [
          _vm.$props.selected
            ? _c("div", {
                class: {
                  "RectangleButton--pickedBorder": _vm.$props.selected,
                },
              })
            : _vm._e(),
          !_vm.$props.selected && _vm.$props.correct
            ? _c("div", {
                class: {
                  "RectangleButton--correctBorder":
                    _vm.$props.correct && _vm.$props.inactive,
                },
              })
            : _vm._e(),
          _vm.$props.selected && _vm.$props.correct && _vm.$props.inactive
            ? _c("div", { staticClass: "RectangleButton--correctIcon" })
            : _vm._e(),
          _c("PickedLabel", { attrs: { disabled: _vm.$props.disabled } }, [
            _vm._v(" " + _vm._s(_vm.$props.label) + " "),
          ]),
          _vm.$props.hasIcon
            ? _c("div", { staticClass: "RectangleButton__icon" })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
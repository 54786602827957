import router from '@/router'
import routeNames from '@/router/routeNames'
import { getterTree, mutationTree, actionTree } from 'typed-vuex'
import axios from 'axios'
import {
  SCID_SSO_URL,
  SCID_SSO_CLIENT_ID,
  SCID_SSO_SCOPE,
  SCID_SSO_OAUTH_URL,
  ENVIRONMENT,
  AUTHENTICATE_PATH,
  MESSAGE_SCID_SUCCESS,
  MESSAGE_SCID_FAILURE,
  MESSAGE_AUTH_SUCCESS,
  MESSAGE_AUTH_FAILURE,
  API_STATUS_PENDING,
  API_STATUS_SUCCESS,
  API_STATUS_FAILURE,
  SUPPORTED_LANGS_EXP,
} from '@/constants'
import guid from '@/helpers/guid'
import { i18n } from '@/config/i18n'

interface State {
  authenticateStatus: string | null
  logoutStatus: string | null

  ssoInited: boolean,
  loginError: boolean
}

const state = (): State => ({
  authenticateStatus: null,
  logoutStatus: null,

  ssoInited: false,
  loginError: false,
})

const getters = getterTree(state, {
  authenticateStatus: state => state.authenticateStatus,
  logoutStatus: state => state.logoutStatus,

  ssoInited: state => state.ssoInited
})

const mutations = mutationTree(state, {
  setAuthenticateStatus: (state, status: string) => {
    state.authenticateStatus = status
  },
  setLogoutStatus: (state, status: string) => {
    state.logoutStatus = status
  },
  setSsoInited: (state, bool: boolean) => {
    state.ssoInited = bool
  },
  setLoginError: (state, bool: boolean) => {
    state.loginError = bool
  },
})

const actions = actionTree(
  { state, getters, mutations },
  {
    ssoInit({ getters, dispatch, commit }) {
      if (window.SSOSDK) {
        const sso = new window.SSOSDK({
          clientId: SCID_SSO_CLIENT_ID,
          scope: SCID_SSO_SCOPE,
          ssoBaseUrl: SCID_SSO_URL,
          onLogin: async ({ accountId, authorizationCode }: { accountId: string; authorizationCode: string }) => {
            dispatch('ssoLoginHandler', { accountId, authorizationCode })
              .then(() => {
                if (!getters.ssoInited) commit('setSsoInited', true)
              })
            .catch(() => {})
          },
          onLogout: async () => {
            dispatch('ssoLogoutHandler')
              .then(() => {
                if (!getters.ssoInited) commit('setSsoInited', true)
              })
              .catch(() => {})
          },
        })

        const { selectedAccountId } = sso.start()
        if (selectedAccountId) {
          // User likely has a valid SSO session and onLogin() will be called soon.
          // Note that this is "optimistic", as the value is returned before
          // making any network calls. The user may have been logged out
          // remotely and instead onLogout() will be called, but if the website's
          // own session is valid and matches the same account as this, it's okay
          // to already show user data here.
        } else {
          // User does not have SSO session. Have the user log in through normal
          // route.

          dispatch('ssoLogoutHandler')
            .then(() => {
              if (!getters.ssoInited) commit('setSsoInited', true)
            })
            .catch(() => {})
        }
      }
    },
    ssoLoginHandler({ rootGetters, dispatch }, { accountId, authorizationCode }: { accountId: string; authorizationCode: string }) {
      return dispatch('authenticate', authorizationCode)
    },
    ssoLogoutHandler({ dispatch, rootGetters }) {
      if (rootGetters['user/isLoggedIn']) {
        return dispatch('logout')
      } else {
        return Promise.resolve()
      }
    },
    ssoLogin({ state, getters, commit, dispatch }) {
      // Save app state before redirecting to SCID
      dispatch('saveAppStateToLocalStorage', null, { root: true })

      // Set and save state for login - used later in the login phase
      const loginState = guid()
      window.localStorage.setItem('loginState', loginState)

      // eslint-disable-next-line no-console
      if (!SCID_SSO_OAUTH_URL && ENVIRONMENT === 'dev') console.warn('DEV NOTE: Missing .env var')

      // Pass locale to SCID login window to localize it
      const langString = `&lang=${i18n.locale}`

      const url =
        SCID_SSO_OAUTH_URL +
        langString +
        '&redirect_uri=' +
        window.location.origin +
        AUTHENTICATE_PATH +
        '&state=' +
        encodeURIComponent(loginState)

      window.location.href = url
    },
    ssoPostLogin({ commit }) {
      const code = decodeURI(router.currentRoute.query.code as string)
      const state = decodeURI(router.currentRoute.query.state as string)
      const loginState = window.localStorage.getItem('loginState')
      window.localStorage.removeItem('loginState')

      if (code && state === loginState) {
        router.push({ name: routeNames.home }).catch(() => {})
      } else {
        // TODO - handle state mismatch
      }
    },
    authenticate({ getters, commit, dispatch }, code: string): Promise<object> {
      // Authenticate SCID user
      commit('setAuthenticateStatus', API_STATUS_PENDING)

      return axios
        .post('/users/login', {
          code: code
        })
        .then(response => {
          dispatch('user/setUserId', response.data.userId, { root: true })
          dispatch('user/setUsername', response.data.username, { root: true })
          dispatch('user/setProfileImage', response.data.profileImage, { root: true })
          if (response.data && response.data.profileImageUploadCooldown) {
            dispatch('user/setProfileImageUploadCooldown', response.data.profileImageUploadCooldown, { root: true })
          }

          commit('setAuthenticateStatus', API_STATUS_SUCCESS)
          return Promise.resolve(response)
        })
        .catch(error => {
          commit('setAuthenticateStatus', API_STATUS_FAILURE)
          return Promise.reject(error)
        })
    },
    logout({ commit, dispatch }) {
      commit('setLogoutStatus', API_STATUS_PENDING)

      return axios
        .post('/users/logout')
        .then(response => {
          dispatch('user/clear', null, { root: true })

          commit('setLogoutStatus', API_STATUS_SUCCESS)
          return Promise.resolve(response)
        })
        .catch(error => {
          dispatch('user/clear', null, { root: true })

          commit('setLogoutStatus', API_STATUS_FAILURE)
          return Promise.reject(error)
        })
    },
  }
)

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

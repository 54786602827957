<template>
  <div
    class="pickedLabel"
    :class="{
      'pickedLabel--disabled': $props.disabled,
    }"
    :style="style">
    <div class="pickedLabel__container">
      <div class="pickedLabel__background" />
      <div class="pickedLabel__label" :style="{ transform: this.notAbsolute ? `skew(0deg)` : '' }">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PickedLabel',
  props: {
    notAbsolute: {
      type: Boolean,
      default: false,
    },
    isLarge: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    style() {
      return {
        '--size': this.isLarge ? '24px' : '18px',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.pickedLabel {
  $block: &;
  @include white-header-font-uppercase('small');
  position: var(--position);
  border-radius: 0;
  z-index: 1;

  &__label {
    @include unskew();
    position: relative;
    font-size: var(--size);
    line-height: 1.1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    filter: drop-shadow(0px 1.5px 0px #000000);

    #{$block}--disabled & {
      opacity: 0.5;
    }
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "pickedLabel",
      class: {
        "pickedLabel--disabled": _vm.$props.disabled,
      },
      style: _vm.style,
    },
    [
      _c("div", { staticClass: "pickedLabel__container" }, [
        _c("div", { staticClass: "pickedLabel__background" }),
        _c(
          "div",
          {
            staticClass: "pickedLabel__label",
            style: { transform: this.notAbsolute ? `skew(0deg)` : "" },
          },
          [_vm._t("default")],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
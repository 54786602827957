export const IS_DEV: boolean = process.env.VUE_APP_ENV === 'dev'
export const APP_VERSION: string | undefined = process.env.VUE_APP_VERSION
export const ENVIRONMENT: string | undefined = process.env.VUE_APP_ENV
export const SENTRY_DSN: string | undefined = process.env.VUE_APP_SENTRY_DSN
export const API_URL: string | undefined = process.env.VUE_APP_API_URL
export const SCID_SSO_URL: string | undefined = process.env.VUE_APP_SCID_SSO_URL
export const SCID_SSO_CLIENT_ID: string | undefined = process.env.VUE_APP_SCID_SSO_CLIENT_ID
export const SCID_SSO_SCOPE: string | undefined = 'social.profile social.profile_image_upload'
export const SCID_SSO_OAUTH_URL: string | undefined = SCID_SSO_URL + '/oauth/authorize?client_id=' + SCID_SSO_CLIENT_ID + '&response_type=code&scope=' + encodeURIComponent(SCID_SSO_SCOPE)
export const AUTHENTICATE_PATH:string = '/auth'

export const GAME_WIDTH = 540
export const GAME_HEIGHT = 540
export const SVG_NS = 'http://www.w3.org/2000/svg'
export const UPLOAD_PIN_SCALE = 1.1

export const MESSAGE_SCID_SUCCESS = 'success'
export const MESSAGE_SCID_FAILURE = 'failure'
export const MESSAGE_AUTH_SUCCESS = 'success'
export const MESSAGE_AUTH_FAILURE = 'failure'

export const API_CODE_OK = 200
export const API_CODE_CREATED = 201
export const API_CODE_BAD_REQUEST = 400
export const API_CODE_UNAUTHORIZED = 401
export const API_CODE_FORBIDDEN = 403
export const API_CODE_NOT_FOUND = 404
export const API_CODE_INTERNAL_SERVER_ERROR = 500

export const API_STATUS_PENDING = 'pending'
export const API_STATUS_SUCCESS = 'successful'
export const API_STATUS_FAILURE = 'failed'

export const DEFAULT_SCALE = 1
export const MAX_SCALE = 1.5
export const MIN_SCALE = 0.5
export const DEFAULT_SELECTED_ASSET_COL = 0
export const DEFAULT_SELECTED_ASSET_ROW = 0

export const PIN_ASSETS_SPRITE = 'AssetExport_540_to-SCv20.svg'

// If we want the print of the pin into the image to be smaller or bigger than the actual scale
export const DRAW_SCALE: number = 0.9

// Automatically center align the uploaded pin
export const CENTER_ALIGN_UPLOAD_PIN = true

// Default pin background color - used when user uploads a pin with transparent background
export const DEFAULT_PIN_BACKGROUND_COLOR = '#e6e6e6'

// Supported lang regex used for url
// Update when new lang is added
export const SUPPORTED_LANGS_EXP =
  '/:lang(ar|da|de|en|es|fa|fi|fr|he|it|id|jp|kr|ms|nl|no|pl|pt|ru|sv|th|tr|vi|zh-Hans|zh-Hant)?'

// Supported languages
// Update when new lang is added
export enum Language {
  ARABIC = 'ar',
  DANISH = 'da',
  PERSIAN = 'fa',
  FINNISH = 'fi',
  HEBREW = 'he',
  MALAY = 'ms',
  INDONESIAN = 'id',
  DUTCH = 'nl',
  NORWEGIAN = 'no',
  POLISH = 'pl',
  RUSSIAN = 'ru',
  SWEDISH = 'sv',
  TURKISH = 'tr',
  THAI = 'th',
  VIETNAMESE = 'vi',
  ENGLISH = 'en',
  GERMAN = 'de',
  FRENCH = 'fr',
  ITALIAN = 'it',
  SPANISH = 'es',
  PORTUGUESE = 'pt',
  JAPANESE = 'jp',
  CHINESE_TRAD = 'zh-Hant',
  CHINESE_SIM = 'zh-Hans',
  KOREAN = 'kr',
}

// Default language for app
export const DEFAULT_LANGUAGE = Language.ENGLISH

// Supported languages for
// Update when new lang is added
export const LANGUAGES = [
  Language.ARABIC,
  Language.DANISH,
  Language.PERSIAN,
  Language.FINNISH,
  Language.HEBREW,
  Language.MALAY,
  Language.INDONESIAN,
  Language.DUTCH,
  Language.NORWEGIAN,
  Language.POLISH,
  Language.RUSSIAN,
  Language.SWEDISH,
  Language.TURKISH,
  Language.THAI,
  Language.VIETNAMESE,
  Language.ENGLISH,
  Language.GERMAN,
  Language.FRENCH,
  Language.ITALIAN,
  Language.SPANISH,
  Language.PORTUGUESE,
  Language.JAPANESE,
  Language.CHINESE_TRAD,
  Language.CHINESE_SIM,
  Language.KOREAN,
]

// Default locale for lang selector
export const DEFAULT_LOCALE = {
  value: Language.ENGLISH,
  label: 'lang_en',
}

// Locale Selector Options
// Update when new lang is added
export const LOCALE_OPTIONS = [
  {
    value: Language.ARABIC,
    label: 'lang_ar',
  },
  {
    value: Language.DANISH,
    label: 'lang_da',
  },
  {
    value: Language.GERMAN,
    label: 'lang_de',
  },
  {
    value: Language.ENGLISH,
    label: 'lang_en',
  },
  {
    value: Language.SPANISH,
    label: 'lang_es',
  },
  {
    value: Language.PERSIAN,
    label: 'lang_fa',
  },
  {
    value: Language.FINNISH,
    label: 'lang_fi',
  },
  {
    value: Language.FRENCH,
    label: 'lang_fr',
  },
  {
    value: Language.HEBREW,
    label: 'lang_he',
  },
  {
    value: Language.ITALIAN,
    label: 'lang_it',
  },
  {
    value: Language.JAPANESE,
    label: 'lang_jp',
  },
  {
    value: Language.KOREAN,
    label: 'lang_kr',
  },
  {
    value: Language.MALAY,
    label: 'lang_ms',
  },
  {
    value: Language.INDONESIAN,
    label: 'lang_id',
  },
  {
    value: Language.DUTCH,
    label: 'lang_nl',
  },
  {
    value: Language.NORWEGIAN,
    label: 'lang_no',
  },
  {
    value: Language.POLISH,
    label: 'lang_pl',
  },
  {
    value: Language.PORTUGUESE,
    label: 'lang_pt',
  },
  {
    value: Language.RUSSIAN,
    label: 'lang_ru',
  },
  {
    value: Language.SWEDISH,
    label: 'lang_sv',
  },
  {
    value: Language.THAI,
    label: 'lang_th',
  },
  {
    value: Language.TURKISH,
    label: 'lang_tr',
  },
  {
    value: Language.VIETNAMESE,
    label: 'lang_vi',
  },
  {
    value: Language.CHINESE_SIM,
    label: 'lang_zh-Hans',
  },
  {
    value: Language.CHINESE_TRAD,
    label: 'lang_zh-Hant',
  },
]


import Vue, { PropType } from 'vue'
import { Color, Direction } from '@/enums'
import { Size } from '@/types'

export default Vue.extend({
  name: 'ChevronIcon',
  props: {
    size: {
      type: Object as PropType<Size>,
      default: () => ({
        width: 24,
        height: 24,
      }),
      validator: (value: Size) =>
        value.width !== undefined &&
        typeof value.width === 'number' &&
        value.height !== undefined &&
        typeof value.height === 'number',
    },
    color: {
      type: String as PropType<Color>,
      default: Color.Black,
      validator: (value: Color) => Object.values(Color).includes(value),
    },
    direction: {
      type: String as PropType<Direction>,
      default: Direction.Down,
      validator: (value: Direction) => Object.values(Direction).includes(value),
    },
  },
})

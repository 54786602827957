
import Vue from 'vue'
import Footer from '@/components/Footer.vue'
import { trackMountedEvent } from '@/helpers/gtm'
import { mapper } from '@/stores/store'

export default Vue.extend({
  name: 'App',
  components: {
    Footer,
  },
  computed: {
    ...mapper(['footerVisible', 'pickingColor']),
    version() {
      return process ? process.env.VUE_APP_VERSION : ''
    },
    showVersion() {
      return process
        ? process.env.VUE_APP_ENV === 'dev' || process.env.VUE_APP_ENV === 'staging'
        : false
    },
  },
  created() {
    this.ssoInit()

    this.showFooter(window.innerWidth >= 1280)
    this.setIsLandscape(window.innerWidth > window.innerHeight)
    this.setIsDesktop(window.innerWidth >= 1280)
  },
  watch: {
    pickingColor(val, old) {
      if (val && !old && this.footerVisible) {
        document.body.style.overflowY = 'hidden'
        ;(this.$refs.appWrapper as HTMLElement).scrollTop = 0
      } else if (!val && old && this.footerVisible) {
        document.body.style.overflowY = 'auto'
      }
    },
  },
  mounted() {
    trackMountedEvent()
    this.addSupercellCookieDisclaimer()

    window.addEventListener('resize', this.onResize)
    this.onResize()

    document.getElementsByTagName('html')[0].setAttribute('version', this.version || '')

    // Add locale class to body to handle locale specific styling
    document.documentElement.lang = this.$i18n.locale
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    ...mapper(['setActiveCategory', 'showFooter', 'setIsLandscape', 'setIsTablet', 'setIsDesktop']),
    ...mapper('auth', ['ssoInit']),
    addSupercellCookieDisclaimer() {
      const cookieConsentScript = document.createElement('script')
      cookieConsentScript.setAttribute('type', 'text/javascript')
      cookieConsentScript.setAttribute('src', '//cdn.cookielaw.org/scripttemplates/otSDKStub.js')
      cookieConsentScript.setAttribute('data-domain-script', '6a02c1b0-2d24-4d05-9760-595e0a86e244')
      cookieConsentScript.setAttribute('async', 'true')
      cookieConsentScript.setAttribute('defer', 'true')

      document.head.appendChild(cookieConsentScript)
      const cookieConsentStyle = document.createElement('link')
      cookieConsentStyle.setAttribute('rel', 'stylesheet')
      cookieConsentStyle.setAttribute(
        'href',
        '//cdn.supercell.com/onetrust/supercell/onetrust_override.css'
      )
      document.head.appendChild(cookieConsentStyle)
    },
    onResize() {
      this.showFooter(window.innerWidth >= 1280)
      this.setIsLandscape(window.innerWidth > window.innerHeight)
      this.setIsTablet(window.innerWidth >= 676)
      this.setIsDesktop(window.innerWidth >= 1280)
      this.updateSizes()
      this.$root.$emit(`resize`, true) // TODO: Slow down resize event
    },
    updateSizes() {
      // Workaround for iOS Safari making sure it always cover the screen
      document.body.style.minHeight = window.innerHeight + 'px'
      document.body.style.minWidth = window.innerWidth + 'px'
      document.body.style.width = '100%'
      document.body.style.minWidth = '100%'
    },
  },
})


import Vue from 'vue'
export default Vue.extend({
  name: 'BaseLink',
  props: {
    href: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '_self',
    },
    download: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
})


import Vue, { PropType } from 'vue'
import { DEFAULT_LOCALE } from '@/constants'
import { Color, Direction } from '@/enums'
import ChevronIcon from './Icons/ChevronIcon.vue'
import GlobeIcon from './Icons/GlobeIcon.vue'

const DESKTOP_SELECT_WIDTH = 160
const DESKTOP_SELECT_MAX_HEIGHT = 269

type SelectOption = {
  value: string
  label: string
}

export default Vue.extend({
  name: 'LocaleSelect',
  components: {
    ChevronIcon,
    GlobeIcon,
  },
  props: {
    value: {
      type: String,
      default: DEFAULT_LOCALE.value,
    },
    options: {
      type: Array as PropType<SelectOption[]>,
      default: () => [],
    },
    textColor: {
      type: String as PropType<Color>,
      default: Color.White,
      validator: (value: Color) => Object.values(Color).includes(value),
    },
  },
  data: function () {
    return {
      isOpen: false,
      alignedRight: false,
      alignedBottom: false,
      desktopSelectItem: null,
      iconSize: { width: 18, height: 18 },
    }
  },
  computed: {
    chevronDirection(): string {
      return this.isOpen ? Direction.Up : Direction.Down
    },
    isMobile(): boolean {
      return window.innerWidth <= 736 // tablet size from sass mq,
    },
  },
  methods: {
    isOptionActive(option: SelectOption): boolean {
      return option.value === this.$props.value
    },
    onChange(e: any): void {
      const selectedOption = this.$props.options.find(
        (option: SelectOption) => option.value === e.target.value
      )
      selectedOption && this.$emit('change', { ...selectedOption })
    },
    onChangeDesktop(e: any): void {
      const targetValue = e.target.getAttribute('data-value')
      const selectedOption = this.$props.options.find((x: SelectOption) => x.value === targetValue)

      // Close when change even for multiple select
      this.onCloseDesktop()

      this.$emit('change', selectedOption)
    },
    toggleDesktop(): void {
      if (this.isMobile) return
      this.isOpen = !this.isOpen
    },
    onClickOutside(e: MouseEvent): void {
      const localeSelect = this.$refs.localeSelect as HTMLElement
      if (!this.isMobile && localeSelect && !localeSelect.contains(e.target as Node | null)) {
        this.onCloseDesktop()
        this.isOpen = false
      }
    },
    onOpenDesktop(): void {
      window.addEventListener('click', this.onClickOutside)
    },
    onCloseDesktop(): void {
      window.removeEventListener('click', this.onClickOutside)
    },
    onResize(): void {
      if (this.$refs.localeSelect) {
        const localeSelect = this.$refs.localeSelect as HTMLElement
        // Check if we need to right-align desktop-select
        const offsetRight = window.innerWidth - (localeSelect.offsetLeft + localeSelect.clientWidth)
        if (offsetRight < DESKTOP_SELECT_WIDTH) this.alignedRight = true
        else this.alignedRight = false

        // Check if we need to bottom-align desktop-select
        let offsetTop = 0
        let parent = localeSelect.offsetParent as HTMLElement

        while (parent) {
          offsetTop += parent.offsetTop
          parent = parent.offsetParent as HTMLElement
        }

        const offsetBottom = document.body.clientHeight - (offsetTop + localeSelect.clientHeight)
        document.body.clientHeight

        if (offsetBottom < DESKTOP_SELECT_MAX_HEIGHT) {
          this.alignedBottom = true
        } else {
          this.alignedBottom = false
        }
      }
    },
  },
  mounted: function () {
    window.addEventListener('resize', this.onResize)
    this.onResize()
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.onResize)
  },
  watch: {
    isOpen() {
      if (this.isOpen) this.onOpenDesktop()
      else this.onCloseDesktop()
    },
  },
})

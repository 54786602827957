
import Vue from 'vue'
import BaseLink from './BaseLink.vue'
import BaseButton from './Buttons/BaseButton.vue'
export default Vue.extend({
  name: 'LinkButtonWrapper',
  components: {
    BaseLink,
    BaseButton,
  },
  props: {
    selector: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '_self',
    },
    download: {
      type: Boolean,
      default: false,
    },
    adaptiveWidth: {
      type: Boolean,
      default: false
    },
    disabled: Boolean,
  },
})

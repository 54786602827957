var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "icon",
      class: { [_vm.direction]: true },
      style: { height: `${_vm.size.height}px`, width: `${_vm.size.width}px` },
    },
    [
      _c(
        "svg",
        {
          staticClass: "icon__svg",
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 448 512",
          },
        },
        [
          _c("path", {
            attrs: {
              fill: _vm.color,
              d: "M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
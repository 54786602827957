// Different categories of elements
export enum Categories {
  'HEAD' = 'head',
  'EYES' = 'eyes',
  'MOUTH' = 'mouth',
  'HAIR' = 'hair',
  'EFFECT' = 'effect',
  'HANDS' = 'hands',
  'ACCESSORIES' = 'accessories',
  'EYEBROWS' = 'eyebrows',
  'BEARD' = 'beard',
  'SKIN_ACCESSORIES' = 'skin_accessories',
  'EARRINGS' = 'earrings',
  'EARS' = 'ears',
  'BACKGROUND' = 'background',
}

// How the elements map to the svg name
export const SVG_ELEMENTS_MAP: Record<string, string> = {
  [Categories.HEAD]: '#Heads',
  [Categories.MOUTH]: '#Mouths',
  [Categories.HAIR]: '#Hair',
  [Categories.EYES]: '#Eyes',
  [Categories.EFFECT]: '#Effects',
  [Categories.HANDS]: '#Hands',
  [Categories.SKIN_ACCESSORIES]: '#Accessories_skin',
  [Categories.EARRINGS]: '#Earrings',
  [Categories.EARS]: '#Ears',
  [Categories.EYEBROWS]: '#Eyebrows',
  [Categories.ACCESSORIES]: '#Accessories',
  [Categories.BEARD]: '#Beard',
}

// Which features are part of the head
export const HEAD_FEATURES: string[] = [
  Categories.ACCESSORIES,
  Categories.HAIR,
  Categories.EYEBROWS,
  Categories.EYES,
  Categories.MOUTH,
  Categories.BEARD,
  Categories.SKIN_ACCESSORIES,
  Categories.HEAD,
  Categories.EARRINGS,
  Categories.EARS,
]

// Which features are part of skin coloring
export const SKIN_COLOR_FEATURES = [Categories.HANDS, Categories.EARS, Categories.HEAD]

// Color supported features
export const COLORING_FEATURES = [
  ...SKIN_COLOR_FEATURES,
  Categories.HAIR,
  Categories.BACKGROUND,
  Categories.BEARD,
]

// During randomize, these features must be there
export const RANDOMIZE_MUST_HAVE_FEATURES = [
  Categories.HEAD,
  Categories.EYES,
  Categories.MOUTH,
  Categories.EARS,
  Categories.BACKGROUND,
]

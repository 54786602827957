import Vue from 'vue'

export interface IRoutesNames {
  home: string
  auth: string
  lang: string
}

const routesNames: Readonly<IRoutesNames> = {
  home: 'home',
  auth: 'auth',
  lang: 'lang',
}

export default routesNames

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      ref: "localeSelect",
      staticClass: "localeSelect",
      class: {
        "localeSelect--alignedRight": _vm.alignedRight,
        "localeSelect--alignedBottom": _vm.alignedBottom,
      },
      on: { click: _vm.toggleDesktop },
    },
    [
      _c(
        "div",
        { staticClass: "localeSelect__globe" },
        [
          _c("GlobeIcon", {
            attrs: { color: _vm.textColor, size: _vm.iconSize },
          }),
        ],
        1
      ),
      _c(
        "select",
        {
          staticClass: "localeSelect__select",
          class: { [_vm.$props.textColor]: _vm.$props.textColor },
          on: { change: _vm.onChange },
        },
        _vm._l(_vm.$props.options, function (option) {
          return _c("option", {
            key: option.value,
            domProps: {
              value: option.value,
              selected: _vm.isOptionActive(option),
              textContent: _vm._s(_vm.$t(option.label)),
            },
          })
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "localeSelect__chevron" },
        [
          _c("ChevronIcon", {
            attrs: {
              size: { width: 12, height: 12 },
              color: _vm.$props.textColor,
              direction: _vm.chevronDirection,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isMobile && _vm.isOpen,
              expression: "!isMobile && isOpen",
            },
          ],
          ref: "desktopSelect",
          staticClass: "localeSelect__desktopOptions",
        },
        _vm._l(_vm.options, function (option, index) {
          return _c(
            "div",
            {
              key: `desktop-select-option-${index}`,
              ref: "desktopSelectItem",
              refInFor: true,
              staticClass: "localeSelect__desktopOption",
              class: { active: _vm.isOptionActive(option) },
              attrs: { "data-value": option.value },
              on: { click: _vm.onChangeDesktop },
            },
            [
              _c("p", {
                staticClass: "localeSelect__desktopLabel",
                domProps: { textContent: _vm._s(_vm.$t(option.label)) },
              }),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
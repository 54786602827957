export default function recursiveFindFill(
  element: SVGGraphicsElement,
  find: string,
  color: string
) {
  if (element.nodeType !== element.ELEMENT_NODE) return

  if (element.getAttribute('fill') === find) {
    element.setAttribute('fill', color)
  }

  if (element.hasChildNodes()) {
    element.childNodes.forEach(childNode =>
      recursiveFindFill(childNode as SVGGraphicsElement, find, color)
    )
  }
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.href || _vm.to
    ? _c(
        "BaseLink",
        {
          attrs: {
            href: _vm.href,
            to: _vm.to,
            target: _vm.target,
            download: _vm.download,
          },
        },
        [_vm._t("default")],
        2
      )
    : _c(
        "BaseButton",
        {
          attrs: {
            disabled: _vm.disabled,
            selector: this.selector,
            adaptiveWidth: _vm.$props.adaptiveWidth,
          },
        },
        [_vm._t("default")],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <BaseLink v-if="href || to" :href="href" :to="to" :target="target">
    <slot />
  </BaseLink>
  <button
    v-else
    class="BaseButton"
    :disabled="disabled"
    :class="{
      'BaseButton--selector': $props.selector,
      'BaseButton--adaptive-width': $props.adaptiveWidth
    }">
    <slot />
  </button>
</template>
<script>
export default {
  name: 'BaseButton',
  props: {
    selector: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '_self',
    },
    adaptiveWidth: {
      type: Boolean,
      default: false
    },
    disabled: Boolean,
  },
}
</script>
<style lang="scss" scoped>
.BaseButton {
  position: relative;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0 5px;
  height: 100%;
  min-width: 20%;

  &--adaptive-width {
    min-width: 0;
  }

  &--selector {
    flex: 1;
  }
}
</style>

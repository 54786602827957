var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "LinkButtonWrapper",
    {
      attrs: {
        to: _vm.$props.to,
        href: _vm.$props.href,
        target: _vm.$props.target,
        "aria-label": _vm.$props.social,
      },
    },
    [
      _c(
        "div",
        {
          ref: "socialButton",
          staticClass: "socialButton",
          class: {
            "socialButton--link": _vm.$props.social === _vm.SocialItems.link,
            "socialButton--twitter":
              _vm.$props.social === _vm.SocialItems.twitter,
            "socialButton--facebook":
              _vm.$props.social === _vm.SocialItems.facebook,
            "socialButton--youtube":
              _vm.$props.social === _vm.SocialItems.youtube,
            "socialButton--instagram":
              _vm.$props.social === _vm.SocialItems.instagram,
            "socialButton--linkedin":
              _vm.$props.social === _vm.SocialItems.linkedin,
            "socialButton--glassdoor":
              _vm.$props.social === _vm.SocialItems.glassdoor,
            "socialButton--bilibili":
              _vm.$props.social === _vm.SocialItems.bilibili,
            "socialButton--tiktok":
              _vm.$props.social === _vm.SocialItems.tiktok,
          },
        },
        [_c("div", { staticClass: "socialButton__icon" })]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
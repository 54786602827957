import Vue from 'vue'
import VueRouter from 'vue-router'
import { routes } from './routes'
import { LANGUAGES } from '@/constants'
import { i18n } from '@/config/i18n'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes,
})

// Route guard for language handling
router.beforeEach((to, _from, next) => {
  // Find Lang in URL
  const urlLang = to.path.split('/')[1]

  const isLangSupported = LANGUAGES.find(lang => {
    return lang === urlLang
  })
  if (isLangSupported) {
    // Set language from route
    i18n.locale = urlLang
  }

  // If there is no lang set in URL, redirect to i18n langauge (browser lang or default lang)
  if (!urlLang) {
    return next({ path: i18n.locale })
  }

  return next()
})
export default router

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      ref: "appWrapper",
      staticClass: "app-wrapper",
      class: { "app-wrapper--noscroll": _vm.pickingColor },
    },
    [
      _vm.showVersion
        ? _c("div", {
            staticClass: "app-wrapper__version",
            domProps: { textContent: _vm._s("v" + _vm.version) },
          })
        : _vm._e(),
      _c("router-view"),
      _vm.footerVisible && !_vm.pickingColor
        ? _c("Footer", {
            on: {
              close: function ($event) {
                return _vm.showFooter(false)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
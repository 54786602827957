import axios from 'axios'
import { API_URL, API_CODE_UNAUTHORIZED } from '@/constants'
import store from '@/stores/store'

axios.defaults.baseURL = API_URL
axios.defaults.withCredentials = true

axios.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response?.status === API_CODE_UNAUTHORIZED) {
    // Log out and clear user data
    store.dispatch('user/clear')
  }

  return Promise.reject(error)
})

import { getterTree, mutationTree, actionTree } from 'typed-vuex'
import { cover } from 'intrinsic-scale'
import cloneDeep from 'lodash/cloneDeep'
import {
  GAME_WIDTH,
  GAME_HEIGHT,
  SVG_NS,
  DEFAULT_SCALE,
  UPLOAD_PIN_SCALE,
  PIN_ASSETS_SPRITE,
  DRAW_SCALE,
  CENTER_ALIGN_UPLOAD_PIN,
  API_STATUS_PENDING,
  API_STATUS_SUCCESS,
  API_STATUS_FAILURE,
  DEFAULT_SELECTED_ASSET_COL,
  DEFAULT_SELECTED_ASSET_ROW,
  DEFAULT_PIN_BACKGROUND_COLOR,
} from '@/constants'
import randomColor from '@/helpers/random-color'
import {
  Categories,
  SKIN_COLOR_FEATURES,
  RANDOMIZE_MUST_HAVE_FEATURES,
  HEAD_FEATURES,
  SVG_ELEMENTS_MAP,
} from '@/constants/elements'
import { trackDownloadEvent } from '@/helpers/gtm'
import axios from 'axios'
import recursiveFindFill from '@/helpers/recursive-find-fill'

const USE_SCALED_DRAW: boolean = DRAW_SCALE !== 1

function createSVGGroup(id: string, width = GAME_WIDTH, height = GAME_HEIGHT): SVGGraphicsElement {
  const svgEl = document.createElementNS(SVG_NS, 'g')
  svgEl.setAttribute('id', id)
  svgEl.setAttribute('x', '0')
  svgEl.setAttribute('y', '0')
  svgEl.setAttribute('width', `${width}`)
  svgEl.setAttribute('height', `${height}`)
  return svgEl
}

export const RECOVER_QUERY_ID = 'recover'
export const RECOVER_QUERY_VALUE = 'true'
const RECOVER_STORAGE_ID = 'pin.recover.state'
export interface PinFeature {
  name: Categories // Category  name
  container: SVGGraphicsElement // SVG container holding the the feature element
  element: SVGGraphicsElement | null // The rendered element in the container
  assets: SVGGraphicsElement[] // Assets that can be copied and become the rendered element
  /** Which asset is selected, index of the assets array. Set to -1 to remove selected */
  selectedAsset: number
  /** Wether its visible or not. */
  hidden: boolean
  /** Selected color. Usually not set directly but set by skinColor or hairColor */
  color: string
  /** SVG position */
  position: { x: number; y: number }
  /** Distance from top-left to where it's initially */
  offset: { x: number; y: number }
  /** Rotation in degrees where 0 is no rotation, 180 half a rotation and 360 a full rotation */
  rotation: number
  /** Scale of the element from 0.75 to 1.25 */
  scale: number
  /** */
  selectedAssetCol: number
  /** */
  selectedAssetRow: number
}

export interface PinFeatures {
  ears: PinFeature
  earrings: PinFeature
  head: PinFeature
  // eslint-disable-next-line camelcase
  skin_accessories: PinFeature
  beard: PinFeature
  mouth: PinFeature
  eyes: PinFeature
  eyebrows: PinFeature
  hair: PinFeature
  hands: PinFeature
  accessories: PinFeature
  effect: PinFeature
  background: PinFeature
}

interface State {
  saveStatus: string | null
  undoCount: number
  pinContainer: SVGElement | null
  features: PinFeatures
  mainFeature: PinFeature // Specifically used for the uploaded pin
  activeBackgroundPatternDesktop: HTMLImageElement | null
}

const history: State[] = []

const featureBase = {
  scale: DEFAULT_SCALE,
  selectedAssetCol: DEFAULT_SELECTED_ASSET_COL,
  selectedAssetRow: DEFAULT_SELECTED_ASSET_ROW,
}

const state = (): State => ({
  saveStatus: null,
  undoCount: 0,
  pinContainer: null,
  features: {
    ears: {
      ...featureBase,
      name: Categories.EARS,
      container: createSVGGroup('ears'),
      element: null,
      assets: [] as SVGGraphicsElement[],
      selectedAsset: -1,
      hidden: false,
      color: '#fff',
      position: { x: 0, y: 0 },
      offset: { x: 0, y: 0 },
      rotation: 0,
    },
    earrings: {
      ...featureBase,
      name: Categories.EARRINGS,
      container: createSVGGroup('earrings'),
      element: null,
      assets: [] as SVGGraphicsElement[],
      selectedAsset: -1,
      hidden: false,
      color: '#fff',
      position: { x: 0, y: 0 },
      offset: { x: 0, y: 0 },
      rotation: 0,
    },
    head: {
      ...featureBase,
      name: Categories.HEAD,
      container: createSVGGroup('head'),
      element: null,
      assets: [] as SVGGraphicsElement[],
      selectedAsset: -1,
      hidden: false,
      color: '#fff',
      position: { x: 0, y: 0 },
      offset: { x: 0, y: 0 },
      rotation: 0,
    },
    skin_accessories: {
      ...featureBase,
      name: Categories.SKIN_ACCESSORIES,
      container: createSVGGroup('skinAccessories'),
      element: null,
      assets: [] as SVGGraphicsElement[],
      selectedAsset: -1,
      hidden: false,
      color: '#fff',
      position: { x: 0, y: 0 },
      offset: { x: 0, y: 0 },
      rotation: 0,
    },
    beard: {
      ...featureBase,
      name: Categories.BEARD,
      container: createSVGGroup('beard'),
      element: null,
      assets: [] as SVGGraphicsElement[],
      selectedAsset: -1,
      hidden: false,
      color: '#fff',
      position: { x: 0, y: 0 },
      offset: { x: 0, y: 0 },
      rotation: 0,
    },
    mouth: {
      ...featureBase,
      name: Categories.MOUTH,
      container: createSVGGroup('mouth'),
      element: null,
      assets: [] as SVGGraphicsElement[],
      selectedAsset: -1,
      hidden: false,
      color: '#fff',
      position: { x: 0, y: 0 },
      offset: { x: 0, y: 0 },
      rotation: 0,
    },
    eyes: {
      ...featureBase,
      name: Categories.EYES,
      container: createSVGGroup('eyes'),
      element: null,
      assets: [] as SVGGraphicsElement[],
      selectedAsset: -1,
      hidden: false,
      color: '#fff',
      position: { x: 0, y: 0 },
      offset: { x: 0, y: 0 },
      rotation: 0,
    },
    eyebrows: {
      ...featureBase,
      name: Categories.EYEBROWS,
      container: createSVGGroup('eyebrows'),
      element: null,
      assets: [] as SVGGraphicsElement[],
      selectedAsset: -1,
      hidden: false,
      color: '#fff',
      position: { x: 0, y: 0 },
      offset: { x: 0, y: 0 },
      rotation: 0,
    },
    hair: {
      ...featureBase,
      name: Categories.HAIR,
      container: createSVGGroup('hair'),
      element: null,
      assets: [] as SVGGraphicsElement[],
      selectedAsset: -1,
      hidden: false,
      color: '#fff',
      position: { x: 0, y: 0 },
      offset: { x: 0, y: 0 },
      rotation: 0,
    },
    hands: {
      ...featureBase,
      name: Categories.HANDS,
      container: createSVGGroup('hands'),
      element: null,
      assets: [] as SVGGraphicsElement[],
      selectedAsset: -1,
      hidden: false,
      color: '#fff',
      position: { x: 0, y: 0 },
      offset: { x: 0, y: 0 },
      rotation: 0,
    },
    accessories: {
      ...featureBase,
      name: Categories.ACCESSORIES,
      container: createSVGGroup('accessories'),
      element: null,
      assets: [] as SVGGraphicsElement[],
      selectedAsset: -1,
      hidden: false,
      color: '#fff',
      position: { x: 0, y: 0 },
      offset: { x: 0, y: 0 },
      rotation: 0,
    },
    effect: {
      ...featureBase,
      name: Categories.EFFECT,
      container: createSVGGroup('effect'),
      element: null,
      assets: [] as SVGGraphicsElement[],
      selectedAsset: -1,
      hidden: false,
      color: '#fff',
      position: { x: 0, y: 0 },
      offset: { x: 0, y: 0 },
      rotation: 0,
    },
    background: {
      ...featureBase,
      name: Categories.BACKGROUND,
      container: createSVGGroup('background'),
      element: null,
      assets: [] as SVGGraphicsElement[],
      selectedAsset: -1,
      hidden: false,
      color: '#2F63DF',
      position: { x: 0, y: 0 },
      offset: { x: 0, y: 0 },
      rotation: 0,
    },
  },
  mainFeature: {
    ...featureBase,
    name: 'main' as Categories,
    container: createSVGGroup('main'),
    element: null,
    assets: [] as SVGGraphicsElement[],
    selectedAsset: -1,
    hidden: false,
    color: '#fff',
    position: { x: 0, y: 0 },
    offset: { x: 0, y: 0 },
    rotation: 0,
  },
  activeBackgroundPatternDesktop: null,
})

const getters = getterTree(state, {
  saveStatus: state => state.saveStatus,
  pinFeatures: state => {
    return Object.values(state.features) as PinFeature[]
  },
  activeFeature: (state, getters, rootState): PinFeature => {
    return state.features[rootState.activeCategory as keyof PinFeatures]
  },
  selectedAsset: (state, getters) => {
    return (getters.activeFeature as PinFeature).selectedAsset
  },
  hasAssets: (state, getters) => {
    const feature = getters.activeFeature as PinFeature
    return feature.assets.length > 1
  },
  assetsLength: (state, getters) => {
    return (getters.activeFeature as PinFeature).assets.length
  },
  visible: (state, getters) => {
    const feature = getters.activeFeature as PinFeature

    if (feature.hidden) {
      return false
    }

    if (feature.assets.length === 0) return true
    return feature.selectedAsset !== -1
  },
  color: (state, getters) => {
    return (getters.activeFeature as PinFeature).color
  },
  position: (state, getters) => {
    return (getters.activeFeature as PinFeature).position
  },
  rotation: (state, getters) => {
    return (getters.activeFeature as PinFeature).rotation
  },
  scale: (state, getters) => {
    return (getters.activeFeature as PinFeature).scale
  },
})

const mutations = mutationTree(state, {
  setSaveStatus: (state, status: string) => {
    state.saveStatus = status
  },
  undo: state => {
    const prevState = history.pop()
    if (prevState) {
      Object.assign(state, prevState)
      state.undoCount = history.length
    }
  },
  saveUndoState: state => {
    history.push(cloneDeep(state))
    state.undoCount = history.length
  },
  setPinContainer: (state, pinContainer: SVGElement) => {
    state.pinContainer = pinContainer
  },
  setActiveBackgroundPatternDesktop(state, pattern: HTMLImageElement | null) {
    state.activeBackgroundPatternDesktop = pattern
  },
  setSkinColor: (state, color: string) => {
    SKIN_COLOR_FEATURES.forEach(name => {
      const featureName = name as keyof PinFeatures
      state.features = {
        ...state.features,
        [featureName]: {
          ...state.features[featureName],
          color: color,
        },
      }
    })
  },
  setHairColor: (state, color: string) => {
    state.features = {
      ...state.features,
      hair: {
        ...state.features.hair,
        color: color,
      },
    }
  },
  setSelectedAssetOf(state, { name, asset }: { name: keyof PinFeatures; asset: number }) {

    state.features = {
      ...state.features,
      [name]: {
        ...state.features[name],
        selectedAsset: asset,
      },
    }
  },
  setHiddenOf(state, { name, hidden }: { name: keyof PinFeatures; hidden: boolean }) {
    state.features = {
      ...state.features,
      [name]: {
        ...state.features[name],
        hidden,
      },
    }
  },
  setColorOf(state, { name, color }: { name: keyof PinFeatures; color: string }) {
    state.features = {
      ...state.features,
      [name]: {
        ...state.features[name],
        color: color,
      },
    }
  },
  setPositionOf(state, { name, x, y }: { name: keyof PinFeatures; x: number; y: number }) {
    state.features = {
      ...state.features,
      [name]: {
        ...state.features[name],
        position: { x, y },
      },
    }
  },
  setOffsetOf(state, { name, x, y }: { name: keyof PinFeatures; x: number; y: number }) {
    state.features = {
      ...state.features,
      [name]: {
        ...state.features[name],
        offset: { x, y },
      },
    }
  },
  setRotationOf(state, { name, rotation }: { name: keyof PinFeatures; rotation: number }) {
    state.features = {
      ...state.features,
      [name]: {
        ...state.features[name],
        rotation,
      },
    }
  },
  setScalingOf(state, { name, scale }: { name: keyof PinFeatures; scale: number }) {
    state.features = {
      ...state.features,
      [name]: {
        ...state.features[name],
        scale,
      },
    }
  },
  setMainFeature(state, settings: object) {
    state.mainFeature = {
      ...state.mainFeature,
      ...settings,
    }
  },
})

const actions = actionTree(
  { state, getters, mutations },
  {
    async initializePin({ state, getters, commit }) {
      if (state.pinContainer) return state.pinContainer

      // Create inline SVG
      const pinContainer = document.createElementNS(SVG_NS, 'svg') // create a new svg element
      commit('setPinContainer', pinContainer)
      pinContainer.setAttribute('xmlns', SVG_NS) // set namespace
      pinContainer.setAttribute('version', '1.1') // set version
      pinContainer.setAttribute('id', 'svgelement') // set the width and height of our svg
      pinContainer.setAttribute('width', '100%') // set the width of the element to 100%
      pinContainer.setAttribute('height', '100%') // set the height of the element to 100%
      pinContainer.setAttribute('viewBox', '0 0 ' + GAME_WIDTH + ' ' + GAME_HEIGHT)
      pinContainer.setAttribute('preserveAspectRatio', 'xMidYMid meet') // set the preserveAspectRatio of the element
      pinContainer.style.transformOrigin = '0 0' // scale from top left
      pinContainer.style.overflow = 'visible'

      // Create a wrapping group of all SVG elements for later scale manipulation
      const mainGroup = createSVGGroup('main')

      // Add dropshadow definition to SVG
      const feDropshadow = document.createElementNS(SVG_NS, 'feDropShadow')
      feDropshadow.setAttribute('dx', '0')
      feDropshadow.setAttribute('dy', '8')
      feDropshadow.setAttribute('stdDeviation', '0')
      feDropshadow.setAttribute('flood-color', '#000')
      feDropshadow.setAttribute('flood-opacity', '1')

      const dropshadowFilter = document.createElementNS(SVG_NS, 'filter')
      dropshadowFilter.setAttribute('id', 'dropshadow')
      dropshadowFilter.appendChild(feDropshadow)

      const defs = document.createElementNS(SVG_NS, 'defs')
      defs.appendChild(dropshadowFilter)

      if (USE_SCALED_DRAW) {
        mainGroup?.appendChild(defs)
      } else {
        pinContainer.appendChild(defs)
      }

      // Create head container and add to SVG
      const headContainer = createSVGGroup('head')
      headContainer.setAttribute('filter', 'url(#dropshadow)')

      if (USE_SCALED_DRAW) {
        mainGroup?.append(headContainer)
      } else {
        pinContainer.append(headContainer)
      }

      // Add dropshadow to hands
      state.features.hands.container.setAttribute('filter', 'url(#dropshadow)')

      // Load assets & add to SVG
      const axiosInstance = axios.create({ baseURL: '/' })
      const res = await axiosInstance.get('/' + PIN_ASSETS_SPRITE)

      // Cleans up the SVG by removing unnecessary elements
      const cleanedRes = res.data.replaceAll(/data-name="[^"]*"/gm, '')

      const parser = new DOMParser()
      const assetsDoc = parser.parseFromString(cleanedRes, 'image/svg+xml')
      getters.pinFeatures.forEach(feature => {
        const svgName = SVG_ELEMENTS_MAP[feature.name]
        if (!svgName) return

        // Add to SVG
        if (HEAD_FEATURES.includes(feature.name)) {
          headContainer.append(feature.container)
        } else {
          if (USE_SCALED_DRAW) {
            mainGroup?.append(feature.container)
          } else {
            pinContainer.append(feature.container)
          }
        }

        // @ts-ignore
        feature.assets = assetsDoc.querySelector(svgName)?.children || []
      })

      if (USE_SCALED_DRAW) {
        pinContainer.append(mainGroup || '')
      }

      // Return the pinContainer so it can be added to the DOM
      return pinContainer
    },
    savePinToLocalStorage({ state}) {
      localStorage.setItem(RECOVER_STORAGE_ID, JSON.stringify(state.features))
    },
    recoverPinFromLocalStorage({ getters, commit }): Promise<void> {
      return new Promise((resolve, reject) => {
        const recoverStateStorage = localStorage.getItem(RECOVER_STORAGE_ID)

        if (!recoverStateStorage) reject(new Error('No recover state found'))
        // Recover state found - Move on with parsing
        else {
          const recoverState: PinFeatures = JSON.parse(recoverStateStorage)

          getters.pinFeatures.forEach(feature => {
            const name = feature.name as keyof PinFeatures
            const recoverFeature: PinFeature = recoverState[name]

            commit('setHiddenOf', { name, hidden: recoverFeature.hidden })
            commit('setColorOf', { name, color: recoverFeature.color })
            commit('setPositionOf', {
              name,
              x: recoverFeature.position.x,
              y: recoverFeature.position.y,
            })
            commit('setOffsetOf', { name, x: recoverFeature.offset.x, y: recoverFeature.offset.y })
            commit('setRotationOf', { name, rotation: recoverFeature.rotation })
            commit('setScalingOf', { name, scale: recoverFeature.scale })
            commit('setSelectedAssetOf', {
              name,
              asset: recoverFeature.selectedAsset,
            })
          })

          localStorage.removeItem(RECOVER_STORAGE_ID)

          resolve()
        }
      })
    },
    savePin({ state, commit, dispatch }): Promise<void | object> {
      commit('setSaveStatus', API_STATUS_PENDING)

      const data = JSON.parse(JSON.stringify(state.features))

      // Add main node settings
      const mainNodeData = JSON.parse(JSON.stringify(state.mainFeature))
      data.main = mainNodeData

      // Add background settings
      if (state.activeBackgroundPatternDesktop) {
        const src = state.activeBackgroundPatternDesktop.src
        const filename = src.split('/').pop()
        data.backgroundPattern = filename
      }

      if (data.background.hidden) {
        data.background.color = DEFAULT_PIN_BACKGROUND_COLOR
        data.background.hidden = false
      }

      return axios
        .post('/pins', data)
        .then(response => {
          commit('setSaveStatus', API_STATUS_SUCCESS)

          // Update user profile image with saved pin
          return dispatch('getUploadPinBlob')
        })
        .then(response => {
          dispatch('user/setUpdatedProfileImage', response, { root: true })
          return Promise.resolve()
        })
        .catch(error => {
          commit('setSaveStatus', API_STATUS_FAILURE)
          return Promise.reject(error)
        })
    },
    randomizePin({ state, getters, commit, dispatch }) {
      // Reset to initial values
      getters.pinFeatures.forEach(feature => {
        const name = feature.name as keyof PinFeatures
        commit('setHiddenOf', { name, hidden: true }) // commit('setHiddenOf', { name, hidden: false })
        commit('setColorOf', { name, color: '#fff' })
        commit('setPositionOf', { name, x: 0, y: 0 })
        commit('setOffsetOf', { name, x: 0, y: 0 })
        commit('setRotationOf', { name, rotation: 0 })
        commit('setScalingOf', { name, scale: DEFAULT_SCALE })
      })

      // Random colors
      commit('setSkinColor', randomColor())
      commit('setHairColor', randomColor())
      commit('setColorOf', {
        name: Categories.BACKGROUND,
        color: randomColor(),
      })
      commit('setColorOf', { name: Categories.BEARD, color: randomColor() })

      // Randomize features
      getters.pinFeatures.forEach(feature => {
        // When feature only has 2 assets, decrease the chance of showing that feature
        const chance = feature.assets.length > 2 ? 0.65 : 0.2
        const mustHave = RANDOMIZE_MUST_HAVE_FEATURES.includes(feature.name)
        const addFeature = mustHave ? true : Math.random() < chance
        const randomAssetNr = Math.round(Math.random() * (feature.assets.length - 1))

        // Fix for correct initial hidden flag
        commit('setSelectedAssetOf', {
          name: feature.name as keyof PinFeatures,
          asset: addFeature ? randomAssetNr : 0,
        })

        commit('setHiddenOf', {
          name: feature.name as keyof PinFeatures,
          hidden: !addFeature,
        })
      })

      // Make sure features have the correct offset after randomize
      getters.pinFeatures.forEach(feature => {
        dispatch('updateFeatureOffset', feature)
      })
    },
    undo({ state, commit, dispatch }) {
      commit('undo')

      // Re-add element because undo loses reference to feature.element
      dispatch('removeFeatureElement', state.features.beard)
      dispatch('addFeatureElement', state.features.beard)
    },
    nextAsset({ commit, state, getters }) {
      const { activeFeature } = getters

      commit('saveUndoState')

      if (activeFeature.hidden) {
        commit('setHiddenOf', {
          name: activeFeature.name as keyof PinFeatures,
          hidden: false,
        })
      }

      commit('setSelectedAssetOf', {
        name: activeFeature.name as keyof PinFeatures,
        asset:
          activeFeature.selectedAsset >= activeFeature.assets.length - 1
            ? 0
            : activeFeature.selectedAsset + 1,
      })
    },
    prevAsset({ commit, state, getters }) {
      const { activeFeature } = getters

      commit('saveUndoState')

      if (activeFeature.hidden) {
        commit('setHiddenOf', {
          name: activeFeature.name as keyof PinFeatures,
          hidden: false,
        })
      }

      commit('setSelectedAssetOf', {
        name: activeFeature.name as keyof PinFeatures,
        asset:
          activeFeature.selectedAsset <= 0
            ? activeFeature.assets.length - 1
            : activeFeature.selectedAsset - 1,
      })
    },
    toggleAsset({ commit, state, getters }) {
      const { activeFeature } = getters

      commit('saveUndoState')

      if (activeFeature.selectedAsset === -1 && activeFeature.assets.length > 0) {
        commit('setSelectedAssetOf', {
          name: activeFeature.name as keyof PinFeatures,
          asset: 0,
        })
      } else {
        commit('setHiddenOf', {
          name: activeFeature.name as keyof PinFeatures,
          hidden: !activeFeature.hidden,
        })
      }
    },
    removeFeatureElement({ state }, feature: PinFeature) {
      Array.from(feature.container.children).forEach(child => child.remove())
      feature.element = null
    },
    async addFeatureElement({ dispatch }, feature: PinFeature) {

      // Create copy of asset and add it to the SVG
      const element = feature.assets[feature.selectedAsset].cloneNode(true) as SVGGraphicsElement
      feature.element = element
      feature.container.append(element)

      // Set element so its in view (tilemap)
      const bb = feature.element.getBBox()
      const col = Math.floor(bb.x / GAME_WIDTH) * GAME_WIDTH + 0
      const row = Math.floor(bb.y / GAME_HEIGHT) * GAME_HEIGHT + 0
      feature.element.setAttribute('transform', `translate(${-col}, ${-row})`)

      feature.selectedAssetCol = col
      feature.selectedAssetRow = row

      dispatch('updateFeatureOffset', feature)
      dispatch('applyElementColor', { feature })
    },
    updateFeatureOffset({ state }, feature: PinFeature) {
      const featureBBox = feature.container.getBBox()

      // Get how much user has moved
      const moved = {
        x: feature.position.x - feature.offset.x,
        y: feature.position.y - feature.offset.y,
      }

      // Save offset, how much SVG is moved from top left corner
      feature.offset = {
        x: featureBBox.x + featureBBox.width / 2,
        y: featureBBox.y + featureBBox.height / 2,
      }

      // Set new position
      feature.position = {
        x: Math.max(Math.min(feature.offset.x + moved.x, GAME_WIDTH), 0),
        y: Math.max(Math.min(feature.offset.y + moved.y, GAME_HEIGHT), 0),
      }
    },
    applyElementColor(
      { state },
      { feature, prevColor = '#fff' }: { feature: PinFeature; prevColor: string }
    ) {
      if (!feature.element) return
      recursiveFindFill(feature.element, prevColor, feature.color)
    },
    setPosition({ commit, getters }, { x, y }: { x: number; y: number }) {
      const { activeFeature } = getters

      commit('setPositionOf', {
        name: activeFeature.name as keyof PinFeatures,
        x,
        y,
      })
    },
    setColor({ commit, state, getters }, color: string) {
      const { activeFeature } = getters

      if (activeFeature.name === Categories.HAIR) {
        commit('setHairColor', color)
        return
      }

      if (SKIN_COLOR_FEATURES.includes(activeFeature.name)) {
        commit('setSkinColor', color)
        return
      }

      commit('setColorOf', {
        name: activeFeature.name as keyof PinFeatures,
        color: color,
      })
    },
    setRotation({ commit, state, getters }, rotation: number) {
      const { activeFeature } = getters

      commit('setRotationOf', {
        name: activeFeature.name as keyof PinFeatures,
        rotation,
      })
    },
    setScale({ commit, state, getters }, scale: number) {
      const { activeFeature } = getters

      commit('setScalingOf', {
        name: activeFeature.name as keyof PinFeatures,
        scale,
      })
    },
    getClonedPinSVG({ state }): SVGElement | void {
      if (!state.pinContainer) return

      const pin = state.pinContainer.cloneNode(true) as SVGElement
      pin.setAttribute('width', GAME_WIDTH + '')
      pin.setAttribute('height', GAME_HEIGHT + '')

      return pin
    },
    async getDownloadPinBlob({ dispatch }): Promise<string> {
      const svg = await dispatch('getClonedPinSVG')

      // Apply draw scale to the main container group of the SVG before print
      if (USE_SCALED_DRAW) {
        const mainGroup = svg.getElementsByTagName('g')[0] as SVGGElement
        mainGroup.setAttribute('transform-origin', `${GAME_WIDTH / 2} ${GAME_HEIGHT / 2}`)
        mainGroup.setAttribute('transform', `scale(${DRAW_SCALE})`)
      }

      const blob = await dispatch('getPinBlob', svg)

      return blob
    },
    async getUploadPinBlob({ state, dispatch, commit }): Promise<string> {
      const svg = await dispatch('getClonedPinSVG')

      // Calculate vertical center based on head size
      if (CENTER_ALIGN_UPLOAD_PIN) {
        // Hide all head elements with hidden flag
        let countHiddenHeadElements = 0
        let feature: PinFeature
        for (const featureKey in state.features) {
          feature = state.features[featureKey as keyof PinFeatures]
          if (HEAD_FEATURES.includes(feature.name) && feature.hidden) {
            feature.container.setAttribute('display', 'none')
            countHiddenHeadElements++
          }
        }

        // Check if all head elements are hidden
        const isAllHeadElementsHidden = HEAD_FEATURES.length === countHiddenHeadElements

        // Calculate (only if at least one head element is visible)
        if (!isAllHeadElementsHidden) {
          const headElm = document.querySelector('#head') as SVGGElement
          const headElmBox = headElm.getBBox()
          const offsetY = (GAME_HEIGHT - headElmBox.height) * 0.5 - headElmBox.y

          // Save settings for later use when sending recipe to backend
          commit('setMainFeature', {
            position: { x: 0, y: offsetY },
            scale: UPLOAD_PIN_SCALE,
          })

          const mainGroup = svg.getElementsByTagName('g')[0] as SVGGElement
          mainGroup.setAttribute('transform-origin', `${GAME_WIDTH / 2} ${GAME_HEIGHT / 2}`)
          mainGroup.setAttribute('transform-box', 'view-box')
          mainGroup.setAttribute('transform', `translate(0 ${offsetY}) scale(${UPLOAD_PIN_SCALE})`)
        }

        // Restore hidden head elements
        for (const featureKey in state.features) {
          feature = state.features[featureKey as keyof PinFeatures]
          if (HEAD_FEATURES.includes(feature.name) && feature.hidden) {
            feature.container.removeAttribute('display')
          }
        }
      }

      const blob = await dispatch('getPinBlob', svg)

      return blob
    },
    async getPinBlob({ state }, svg: SVGElement): Promise<string> {
      /**
       * Create pin image
       */

      const pin = svg

      const pinImage = new Image()
      pinImage.src = URL.createObjectURL(
        new Blob([pin.outerHTML], {
          type: 'image/svg+xml',
        })
      )
      await new Promise((resolve, reject) => {
        pinImage.onload = () => resolve(true)
        pinImage.onerror = () => reject(new Error())
      })

      /**
       * Create canvas to render SVG pin & background in
       */

      const scale = 1024 / GAME_WIDTH
      const canvas = document.createElement('canvas')
      canvas.style.transformOrigin = '0 0' // scale from top left
      canvas.width = 1024
      canvas.height = 1024
      const ctx = canvas.getContext('2d', { alpha: true })!
      ctx.scale(scale, scale)

      /**
       * Draw gradient
       */

      if (!state.features.background.hidden) {
        ctx.fillStyle = 'white'
        ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height)

        const gradient = ctx.createRadialGradient(
          512 / scale,
          512 / scale,
          75,
          512 / scale,
          512 / scale,
          250
        )

        gradient.addColorStop(0, state.features.background.color + '40')
        gradient.addColorStop(0.5, state.features.background.color + '99')
        gradient.addColorStop(1, state.features.background.color)

        ctx.fillStyle = gradient
        ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height)
      }

      /**
       * Draw SVG pin & background
       */

      const drawPinInCanvas = () => {
        ctx.drawImage(pinImage, 0, 0, GAME_WIDTH, GAME_HEIGHT)
      }

      if (state.activeBackgroundPatternDesktop) {
        const bgImage = new Image()
        bgImage.src = state.activeBackgroundPatternDesktop.src
        await new Promise(resolve => (bgImage.onload = () => resolve(true)))

        const { width, height, x, y } = cover(
          ctx.canvas.width,
          ctx.canvas.height,
          bgImage.width,
          bgImage.height
        )

        ctx.drawImage(bgImage, 0, 0, bgImage.width, bgImage.height, x, y, width, height)

        drawPinInCanvas()
        bgImage.remove()
      } else {
        drawPinInCanvas()
      }

      // Create base64 data
      const data = canvas.toDataURL()

      // Cleanup
      pin.remove()
      canvas.remove()

      return data
    },
    async downloadPin({ state, dispatch }) {
      const data = await dispatch('getDownloadPinBlob')

      /**
       * Create actual download link
       */
      trackDownloadEvent()

      // Define fallback download method
      const link = document.createElement('a')
      const makeDownload = () => {
        link.download = 'pin.png'
        link.href = data
        link.innerHTML = 'Download PNG'
        link.click()
      }

      // Create a file from the base64 data
      const arr = data.split(',')
      // @ts-ignore: Array is possible null
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      // Create files array for native share
      const files = [
        new File([u8arr], 'pin.png', { type: mime, lastModified: new Date().getTime() }),
      ]

      const mobileRegexp = /android|iphone|kindle|ipad/i
      const isMobile = mobileRegexp.test(navigator.userAgent)
      const canUseNativeShare = isMobile && navigator.canShare && navigator.canShare({ files })

      // Try native share or fallback to download method
      if (canUseNativeShare) {
        try {
          navigator.share({
            files,
            title: 'Brawl Stars Pin Maker',
            text: 'Download your Brawl Stars pin!',
          })
        } catch (error) {
          // Fallback to download method
        }

        // Detect if iOS and run pin storage
        if (window && /iP(ad|od|hone)/i.test(window.navigator.userAgent)) {
          localStorage.setItem(RECOVER_STORAGE_ID, JSON.stringify(state.features))
          window.location.href = `${window.location.pathname}?${RECOVER_QUERY_ID}=${RECOVER_QUERY_VALUE}`
        }
      } else {
        makeDownload()
      }

      /**
       * Cleanup
       */
      link.remove()
    },
  }
)

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
